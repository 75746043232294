import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class StaticElementsService {
	dataType = [
		{
			label: 'Texto Corto',
			elementHTML: 'input',
			advance: true
		},
		{
			label: 'Texto largo',
			elementHTML: 'textarea',
			advance: true
		},
		{
			label: 'Números',
			elementHTML: 'input (number)',
			advance: true
		},
		{
			label: 'Fecha',
			elementHTML: 'input (date)',
			advance: true
		},
		{
			label: 'Hora',
			elementHTML: 'input (time)',
			advance: true
		},
		{
			label: 'Opción Única',
			elementHTML: 'radio',
			advance: true
		},
		{
			label: 'Opción Multiple',
			elementHTML: 'checkbox',
			advance: true
		},
		{
			label: 'Archivo PDF',
			elementHTML: 'file (pdf)',
			advance: true
		},
		// {
		// 	label: 'Archivo EXCEL',
		// 	elementHTML: 'file (xlsx)',
		// 	advance: true
		// },
		{
			label: 'Archivo IMAGEN',
			elementHTML: 'file (image)',
			advance: true
		},
		{
			label: 'Zonas FAO',
			elementHTML: 'fao_zones',
			advance: false
		},
		{
			label: 'Geo Navieras',
			elementHTML: 'api (navieras)',
			advance: false
		},
		{
			label: 'SIAP',
			elementHTML: 'api (siap)',
			advance: false
		},
		{
			label: 'Georeferencia',
			elementHTML: 'georeference',
			advance: false
		},
		{
			label: 'Decisión',
			elementHTML: 'decision',
			advance: false
		}
	];

	fao_zones = [
		{ key: '18' },
		{ key: '12' },
		{ key: '27' },
		{ key: '31' },
		{ key: '34' },
		{ key: '37' },
		{ key: '41' },
		{ key: '47' },
		{ key: '48' },
		{ key: '51' },
		{ key: '57' },
		{ key: '58' },
		{ key: '61' },
		{ key: '67' },
		{ key: '71' },
		{ key: '77' },
		{ key: '81' },
		{ key: '87' },
		{ key: '88' }
	];

	languages = [
		{
			language: 'ALEMAN',
			prefix: 'de'
		},
		{
			language: 'COREAN',
			prefix: 'ko'
		},
		{
			language: 'ESPAÑOL',
			prefix: 'es'
		},
		{
			language: 'FRANCES',
			prefix: 'fr'
		},
		{
			language: 'INGLES',
			prefix: 'en'
		},
		{
			language: 'INDONESIO',
			prefix: 'id'
		},
		{
			language: 'IRLANDES',
			prefix: 'ga'
		},
		{
			language: 'ITALIANO',
			prefix: 'it'
		},
		{
			language: 'JAPONES',
			prefix: 'ja'
		},
		{
			language: 'PORTUGUES',
			prefix: 'pt'
		},
		{
			language: 'RUSO',
			prefix: 'ru'
		}
	];
}
